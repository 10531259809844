<template>
  <div class="main-content-group-school">
    <div class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="timelinePage" />
      </div>
    </div>
    <b-card>
      <div class="title-page-schoolgroup mb-2">
        <h4>Quản lý tổ trong khối: {{textSchoolGroup}}</h4>
      </div>
      <div class="top-page-custom-group mt-2">
        <b-row>
          <b-col lg="4">
            <div class="search-school-block-input">
              <b-form-input v-model="modelSchoolGroupSearch.name" @keyup.enter="getListAllSchoolGroup" type="text" placeholder="Nhập tên tổ để tìm kiếm" />
            </div>
          </b-col>
          <b-col lg="4">
            <div class="acction-search-school-block">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="getListAllSchoolGroup"
                  variant="outline-primary"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">Tìm kiếm</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="body-page-group">
        <b-row>
          <b-col lg="12">
            <div class="top-table-school-block pt-2">
              <b-row>
                <b-col>
                  <div class="select-perpage-department">
                    <b-form-group
                    >
                      <label class="d-inline-block text-sm-left mr-50">Số bản ghi trên một trang</label>
                      <b-form-select
                          id="perPageSelect"
                          v-model="perrPage"
                          size="sm"
                          :options="listPerPageSchoolGroup"
                          class="w-50"
                      />
                    </b-form-group>
                  </div>
                </b-col>
                <b-col lg="10" style="margin: auto">
                  <div class="acction-add-school-group">
                    <b-button @click="getFormCreateSchoolGroup"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                    >
                      <feather-icon
                          icon="PlusIcon"
                          class="mr-50"
                      />
                      <span class="align-middle">Thêm mới</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="table-school-block-data">
              <b-table
                  :per-page="perrPage"
                  :current-page="currentPage"
                  :fields="fieldSchoolGroup"
                  :items="listSchoolGroup">
                <template #cell(index)="data">
                  <span>{{data.index + 1}}</span>
                </template>
                <template #cell(hanhdong)="data">
                  <b-button @click="getFormUpdateSchoolGroup(data.item.id)" v-b-tooltip.hover.top="'Cập nhật'" variant="flat-primary" class="btn-icon"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                    <b-button v-b-tooltip.hover.top="'Xóa'"
                              variant="flat-danger"
                              class="btn-icon" @click="deleteSchoolGroup(data.item)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                </template>
              </b-table>
              <b-pagination
                  :total-rows="rows"
                  class="float-right mt-2"
                  v-model="currentPage"
                  :per-page="perrPage"
                  hide-goto-end-buttons>
              </b-pagination>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal ref="modal-school-group"
             size="sm"
             hide-header
             hide-footer>
      <div class="title-header-modal">
        <h4 class="card-title">{{isSchoolGroup? 'Cập nhật' : 'Tạo mới'}} tổ trong khối: {{textSchoolGroup}}</h4>
      </div>
      <validation-observer ref="modelSchoolBlock">
        <div class="main-modal-school-block">
          <b-form-group>
            <validation-provider #default="{ errors }" name="Tên tổ không được để trống" rules="required">
              <label class="font-weight-bold">Tên khối</label>
              <b-form-input :class="errors.length > 0 ? 'is-invalid':null" v-model="modelSchoolGroup.organizationBranchName" placeholder="Nhập tên khối"></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </div>
        <div class="footer-modal-school-block">
          <b-button v-if="!isSchoolGroup" size="sm" class="mr-1" @click="addSchoolGroup"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
          >
            <feather-icon icon="CheckIcon" />
            <span class="align-middle">Tạo mới</span>
          </b-button>
          <b-button v-if="isSchoolGroup" size="sm" class="mr-1" @click="updateSchoolGroup"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-success"
          >
            <feather-icon icon="CheckIcon" />
            <span class="align-middle">Cập nhật</span>
          </b-button>
          <b-button size="sm" @click="closeModal"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-danger"
          >
            <feather-icon icon="XIcon" />
            <span class="align-middle">Đóng</span>
          </b-button>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, VBTooltip, BFormInput, BButton, BTable, BModal, BFormGroup, BPagination, BBreadcrumb, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BModal,
    BFormGroup,
    BPagination,
    BBreadcrumb,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      perrPage: 10,
      currentPage: 1,
      isSchoolGroup: false,
      listPerPageSchoolGroup: [3, 5, 10],
      required,
      totalCount: null,
      fieldSchoolGroup: [
        {
          key: 'index', label: 'Stt', thClass: 'custom-th-school-block-stt',
        },
        {
          key: 'organizationBranchName', label: 'Tên tổ',
        },
        {
          key: 'hanhdong', label: 'Hành động', thClass: 'custom-th-school-block', tdClass: 'custom-td-school-block',
        },
      ],
      listSchoolGroup: [
        3, 5, 10,
      ],
      idSchoolBlock: null,
      idDepartment: null,
      modelSchoolGroup: {
        parentId: null,
        organizationBranchName: '',
      },
      idSchoolGroup: null,
      textDepartment: '',
      textSchoolGroup: '',
      modelSchoolGroupSearch: {
        departmentId: '',
        name: '',
        pageIndex: 0,
        pageSize: 0,
      },
    }
  },
  created() {
    const parameters = this.$route.query
    this.idSchoolBlock = parameters.idSchoolBlock
    this.idDepartment = parameters.idDepartment
    this.textDepartment = parameters.nameDepartment
    this.modelSchoolGroup.parentId = parameters.idSchoolBlock
    if (parameters) {
      this.textSchoolGroup = parameters.nameSchoolBlock
      this.timelinePage = [
        {
          text: 'phòng ban',
          href: '/department',
        },
        {
          text: `${this.textDepartment}`,
          href: '/department',
        },
        {
          text: 'Khối',
          href: `/department-school-block?id=${this.idDepartment}&department=${this.textDepartment}`,
        },
        {
          text: `${this.textSchoolGroup}`,
          href: `/department-school-block?id=${this.idDepartment}&department=${this.textDepartment}`,
        },
        {
          text: 'Tổ',
        },
      ]
    }
    this.getListAllSchoolGroup()
  },
  methods: {
    getListAllSchoolGroup() {
      this.$crm.post(`organization-branch-school/find-all-group-by-condition/${this.idSchoolBlock}`, this.modelSchoolGroupSearch).then(res => {
        this.listSchoolGroup = res.data
        this.totalCount = this.listSchoolGroup.length
      })
    },
    addSchoolGroup() {
      this.$refs.modelSchoolBlock.validate().then(success => {
        if (success) {
          this.$crm.post('organization-branch-school/create-group', this.modelSchoolGroup).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo mới tổ thành công',
              },
            })
            this.getListAllSchoolGroup()
            this.$refs['modal-school-group'].hide()
          })
        }
      })
    },
    updateSchoolGroup() {
      this.$refs.modelSchoolBlock.validate().then(success => {
        if (success) {
          this.$crm.post(`organization-branch-school/update-group/${this.idSchoolGroup}`, this.modelSchoolGroup).then(res => {
            this.modelSchoolBlock = res.data
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Cập nhật tổ thành công',
              },
            })
            this.$refs['modal-school-group'].hide()
            this.getListAllSchoolGroup()
          })
        }
      })
    },
    deleteSchoolGroup(item) {
      this.$swal({
        title: 'Xóa tổ',
        text: `Bạn có muốn xóa tổ ${item.organizationBranchName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Đồng ý',
        cancelButtonText: 'Hủy bỏ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$crm.get(`organization-branch-school/delete-organization-branch/${item.id}`).then(res => {
            this.getListAllSchoolGroup()
            this.$swal({
              icon: 'success',
              title: 'Xóa tổ',
              text: 'Xóa tổ thành công',
              showConfirmButton: false,
              timer: 1500,
            })
          })
        }
      })
    },
    closeModal() {
      this.$refs['modal-school-group'].hide()
    },
    getFormCreateSchoolGroup() {
      this.isSchoolGroup = false
      this.modelSchoolGroup.organizationBranchName = ''
      this.$refs['modal-school-group'].show()
    },
    getFormUpdateSchoolGroup(id) {
      this.isSchoolGroup = true
      this.idSchoolGroup = id
      if (id) {
        this.$crm.post(`organization-branch-school/find-group-by-id/${id}`).then(res => {
          this.modelSchoolGroup = res.data
          this.$refs['modal-school-group'].show()
        })
      }
    },
  },
  computed: {
    rows() {
      if (this.listSchoolGroup) {
        return this.listSchoolGroup.length
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../../@core/scss/custom/department';
</style>
